import React from "react"
import ImageUrlRenderer from "../../atoms/ImageUrlRenderer"
import BrandParagraph from "../../atoms/BrandParagraph"
import Button from "../../atoms/Button"
import SectionTitle from "../../atoms/SectionTitle"

const NeedHelpSection = ({ data }) => {
  const imageUrl = ImageUrlRenderer({ img: data.bgImage })
  return (
    <div className="cc-section--horizontal-space flex flex-col lg:flex-row items-center justify-center gap-[90px] md:gap-[35px]">
      <div
        className="flex-[2] w-full hide-background-mobile md:bg-cover bg-center text-center lg:min-h-[582px] flex flex-col items-center justify-center"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <h3 className="text-[38px] leading-[1.4em] font-[700] font-ubuntu text-[#25282C]">
          {data.needHelpTitle}
        </h3>
        <BrandParagraph text={data.needHelpDescription} textEC={`text-[24px] mt-2 mb-3`} />
        <div className="my-5">
          <Button
            to={data.btnLink}
            href={data.btnLink}
            text={data.btnText}
            ec={`secondary-btn`}
          />
        </div>
      </div>
      <div className="flex-[1]">
        <SectionTitle title={data.sectionTitle} titleEC={`mb-5`} />
        <BrandParagraph 
            text={data.sectionDescription}
        />
      </div>
    </div>
  )
}

export default NeedHelpSection
