import { faSearch } from "@fortawesome/free-solid-svg-icons"

export const helpAndSupportPage = {
  title: `How can we <span>help?</span>`,
  description: `Get the answers you need for any issues or questions you may have. Our Help center is here to provide support and solutions for you.`,
  googleReviewText: `Care Casa’s SDA home services have completely transformed my life. I have gained the independence and comfort I desire. I highly recommend them!`,
  formDetails: {
    formFieldIcon: faSearch,
    formFieldPlaceholderText: `Search help articles...`,
    buttonText: `Search`,
  },
}

export const articles = [
  // {
  //   title: `Security and Fraud`,
  //   slug: `security-and-fraud`,
  //   faqs: [
  //     {
  //       question: `Security and Fraud Why choose us?`,
  //       answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sed feugiat nisi. Morbi dictum lorem eu velit facilisis, ut dapibus felis dignissim. Aenean non leo quis urna tempor luctus in at dolor. Etiam sit amet urna dictum, bibendum dui id, rutrum justo. Curabitur aliquet neque nec nisl venenatis, ut vestibulum magna faucibus. Aliquam augue elit, varius vitae convallis quis, vehicula at purus. Donec interdum arcu a lectus vulputate, sed egestas nisl tempus. Nam massa sem, scelerisque ac augue non, gravida venenatis ipsum.`,
  //     },
  //   ],
  // },
]


// Need Help Section
export const needHelpSection = {
    bgImage: `help-support--need-help-bg`,
    needHelpTitle: `Still need help?`,
    needHelpDescription: `We’re here for you.`,
    btnText: `Contact Support`,
    btnLink: `/contact-us/`,
    sectionTitle: `<span>Support</span>`,
    sectionDescription: `
    At Care Casa, we're here to assist you every step of the way. Whether you have questions about our SDA housing services, need guidance on using our platform, or require support with your NDIS journey, our team is ready to help. Visit our support section for answers to frequently asked questions or to connect with our friendly support team. <br><br>We’re dedicated to ensuring you have the best possible experience with our services.
    `
}


// Faqs
export const faqs = [
  {
    question: `What is Specialist Disability Accommodation (SDA), and how does Care Casa support it?`,
    answer: `Specialist Disability Accommodation (SDA) refers to personalised housing options with support services designed to assist individuals with disabilities in living as independently as possible. Care Casa provides tailored SDA solutions, including accessible housing options and personalised support plans that cater to each resident's unique needs.`,
  },
  {
    question: `Who is eligible to live at Care Casa?`,
    answer: `Care Casa welcomes individuals with disabilities who require specialist disability accommodation. Our homes are designed to accommodate various needs, and we work closely with potential residents and their families to ensure suitability and compatibility.`,
  },
  {
    question: `How does Care Casa assist with navigating the National Disability Insurance Scheme (NDIS)?`,
    answer: `We have dedicated NDIS coordination services to help residents navigate the complexities of the NDIS. Our team assists with planning, funding applications, and connecting residents with the proper support and services under their NDIS plan.`,
  },
  {
    question: `What types of accommodations does Care Casa offer?`,
    answer: `Care Casa offers a variety of accommodation options tailored to meet the diverse needs of our residents. These include single-bedroom apartments, shared living arrangements, and family-style homes, all with accessibility features and designed for comfort.`,
  },
  {
    question: `What support services are available at Care Casa?`,
    answer: `Our support services are comprehensive and personalised. They include assistance with daily living activities, personal care support, community participation programs, and access to healthcare services. Each resident receives a personalised support plan based on their needs and preferences.`,
  },
  {
    question: `How does Care Casa promote community engagement among residents?`,
    answer: `We prioritise community engagement through various activities and programs. Residents can participate in social events, recreational activities, and educational workshops. These initiatives foster a sense of belonging and encourage social interaction among residents.`,
  },
  {
    question: `How does Care Casa promote community engagement among residents?`,
    answer: `We prioritise community engagement through various activities and programs. Residents can participate in social events, recreational activities, and educational workshops. These initiatives foster a sense of belonging and encourage social interaction among residents.`,
  },
  {
    question: `What safety and accessibility features are available in Care Casa homes?`,
    answer: `Our homes are designed with safety and accessibility in mind. Features include wheelchair-accessible entrances, modified bathrooms and kitchens, emergency call systems, secure parking options, and accessibility to common areas within the community.`,
  },
  {
    question: `How can I schedule a tour of Care Casa or apply for residency?`,
    answer: `We encourage anyone interested in learning about Care Casa to schedule a tour of our facilities. During the tour, you'll see our accommodations and meet our team. To apply for residency, contact us directly, and we'll guide you through the application process, including eligibility requirements and availability.`,
  },
  // More questions...
]
